import "./Onboarding.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as commonActionCreators } from "../../store/Common";
import { ApplicationState } from "../../store";
import html2canvas from "html2canvas";
import thrivingPetsLogoLight from "../../assets/icons/thrivingpets-logo-light.svg";
import vetXtendLogo from "../../assets/img/logo.svg";
import vetXtendLogoLight from "../../assets/images/vetxtend-logo-light.svg";
import thrivingPetsLogoDarkReverse from "../../assets/icons/thrivingpets-logo-dark-reverse.svg";
import truckIcon from "../../assets/images/icons/truck.svg";
import thrivingPetsLogo from "../../assets/icons/thrivingpets-logo-dark.svg";
import chatIcon from "../../assets/icons/chat.svg";
import checkIcon from "../../assets/icons/check.svg";
import arrowLeftIcon from "../../assets/icons/arrow-left.svg";
import arrowRightIcon from "../../assets/icons/arrow-right.svg";
import hospitalIcon from "../../assets/icons/hospital.svg";
import injectionIcon from "../../assets/icons/injection.svg";
import stethoscopeIcon from "../../assets/icons/stethoscope.svg";
import toothbrushIcon from "../../assets/icons/toothbrush.svg";
import desexedIcon from "../../assets/icons/desexed.svg";
import storeIcon from "../../assets/icons/store.svg";
import calculatorIcon from "../../assets/icons/calculator.svg";
import faqIcon from "../../assets/icons/faq.svg";
import dogAndCat from "../../assets/icons/dog-and-cat.svg";
import trashIcon from "../../assets/icons/trash.svg";
import editIcon from "../../assets/icons/edit.svg";
import homeIcon from "../../assets/icons/home-light.svg";
import shopIcon from "../../assets/icons/shop-light.svg";
import padlockIcon from "../../assets/icons/padlock.svg";
import inclusionImage from "../../assets/img/inclusions-image.svg";
import { Row, Modal } from "../../components/layout";
import history from "../../utils/history";

type HeaderProps = {
  step: number;
  stepTotal: number;
  disableProgress?: boolean;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const YPPA_URL =
  window.location.host != "app.vetxtend.com.nz"
    ? "https://yourpetpa.com.au/"
    : "https://yourpetpa.co.nz";
const VETXTEND_URL =
  window.location.host != "app.vetxtend.co.nz"
    ? "https://vetxtend.com.au"
    : "https://vetxtend.com.nz";

const TermsAndConditions: React.VFC<any> = (data) => {
  const { app } = data;
  const country = app == "NZ" ? "New Zealand" : "Australia";
  console.log({ country }, app);
  return (
    <div className="onboarding page">
      <h4>Terms & Conditions</h4>
      <h5>1. Access to Practice Data</h5>
      <p>
        The Practice will provide reasonable assistance to VetXtend during
        implementation of the Services, in particular to facilitate access by
        VetXtend to the Practice's practice management system and finance
        system, including permission from the practice management system
        provider (as necessary) to provide the Services and/or access to an API
        or similar tool required for integration of the practice management
        system and finance system for Practice Performance Reporting.
      </p>
      <h5>2. Third Party Licences</h5>
      <p>
        Any third-party licences required by the Practice for access to the
        Practice Performance Reporting (for example, for Microsoft Power BI Pro)
        will be procured directly by the Practice and the Practice will be bound
        by and required to comply with the third-party licence terms.
      </p>
      <h5>3. Ownership of Content</h5>
      <p>
        The ownership of data and/or information provided by a Practice and all
        adaptations and modifications of any data and/or information effected by
        VetXtend in the course of providing the Services to a Practice under
        this Agreement remain vested in the Practice at all times unless
        otherwise agreed by the parties in writing. The Practice agrees and
        grants VetXtend the right to access and use such data and/or information
        for the purposes of VetXtend providing the Services and for VetXtend to
        conduct data analytics to help improve the Services.
      </p>
      <p>
        The Practice grants to VetXtend at no cost a non-exclusive, {country}
        {""} wide licence to use the Practice's trademark and any other
        Intellectual Property Rights as necessary during the Term for the
        purposes of supplying the Services, including the preparation and
        sending of the Practice-Branded Emails.
      </p>
      <h5>4. Use of Practice Data</h5>
      <p>
        Unless agreed otherwise between the parties, VetXtend (including its
        employees, consultants, Related Persons and professional advisers) will
        only use Practice data for the purposes of providing the Services.
        VetXtend will not provide or sell Practice data to third parties in
        {""} {country} or overseas.
      </p>
      <h5>5. Ownership of Software and Intellectual Property</h5>
      <p>
        The Practice acknowledges and agrees that all Intellectual Property
        Rights, including those subsisting in and to the software and technology
        stack relating to the Practice Performance Reporting and associated
        systems (Software) and any materials made available to the Practice or
        otherwise to supplied in connection with the Services, notwithstanding
        any licences granted to, or access and use by, the Practice, remain
        vested in and the property of VetXtend.
      </p>
      <p>
        The Practice must not take any action, or cause any related entity or
        third party to take any action, challenging, contesting or in any way
        impairing the software ownership or any other Intellectual Property
        Rights of VetXtend.
      </p>
      <p>
        The Practice must not modify, copy, reproduce, disassemble, adapt,
        translate, or create any derivative works of the Software, or
        disseminate, distribute, transmit, display, perform, publish, directly
        or indirectly sell, transfer, offer for sale, licence, assign, rent or
        sublicense any part of the Software or any copies of the Software.
      </p>
      <h5>6. Termination</h5>
      <h5>6.1 Termination at Will</h5>
      <p>
        The Practice may terminate this Agreement by providing VetXtend with 30
        days’ notice in writing.
      </p>
      <h5>6.2 Termination for Cause</h5>
      <p>
        A party <strong>(terminating party)</strong> may terminate this
        Agreement immediately by written notice to the other party
        {""} <strong>(defaulting party)</strong> if:
      </p>
      <ol style={{ listStyle: "none" }}>
        <li>
          (a) the defaulting party commits a material breach of this agreement,
          and fails to rectify that breach within 14 days of receiving a written
          notice from the terminating party specifying that breach and
          requesting its rectification;
        </li>
        <li>
          (b) the defaulting party commits a material breach that is not capable
          of being remedied within 14 days; or
        </li>
        <li>
          (c) the defaulting party has an administrator appointed or is declared
          insolvent.
        </li>
      </ol>
      <p>
        VetXtend reserves the right at any time to terminate this Agreement with
        the Practice on reasonable written notice (of no less than 7 days) to
        the Practice if, in its sole discretion (acting reasonably), VetXtend
        suspects or determines the Practice has breached or is in breach of
        clause 15 in this Schedule 1.
      </p>
      <h5>6.3 Consequences of Termination</h5>
      <p>The effect of termination Is as follows:</p>
      <ul>
        <li>
          From the termination date, the Practice will no longer have access to
          the Practice Performance Reporting platform VetXtend had established
          for it;
        </li>
        <li>
          VetXtend will cease all Practice-Branded Emails to the Practice's
          clients (for the avoidance of doubt, there is no restriction on
          VetXtend communicating with clients that were existing VetXtend
          customers at the date of this Agreement or otherwise purchases
          products from VetXtend independently of this Agreement);
        </li>
        <li>
          VetXtend will take all reasonable steps to return or remove all
          records or data of the Practice that is in VetXtend’s possession or
          control;
        </li>
        <li>
          The Practice must pay VetXtend any outstanding fees (based on the
          pricing structures set out in clause 3.2 of this Agreement) at the
          termination date; and
        </li>
        <li>
          VetXtend will pay any unpaid commissions and rebates accrued and owing
          to the Practice up to the termination date.
        </li>
      </ul>
      <p>
        All rights and obligations under this Agreement terminate on the date of
        termination other than any rights and obligations, and any other clause,
        that is expressly stated to continue after termination of the agreement
        or which by necessary implication must continue after termination.
      </p>
      <h5>7. Dispute</h5>
      <p>
        If a dispute arises between the parties in connection with this
        Agreement, the parties must discuss and attempt to resolve the dispute
        in good faith, and on the basis that the parties wish to preserve and
        maintain their commercial relationship for the period of this Agreement.
      </p>
      <p>
        If a Dispute is not, or cannot be, resolved within 30 days of the
        dispute first being raised in writing between the parties, either party
        may commence or initiate court proceedings to resolve that dispute,
        and/or obtain remedies available to it in respect of the circumstances
        giving rise to that dispute. This clause does not limit a party's
        ability to seek urgent injunctive or other interlocutory relief.
      </p>
      <h5>8. Confidentiality</h5>
      <p>
        Any information not otherwise publicly available and provided or made
        available to VetXtend by the Practice, or by VetXtend to the Practice,
        is considered Confidential Information and must be kept confidential. It
        is only to be used for the purpose of providing the Services under this
        Agreement and not for any other purpose. No party may use any
        Confidential Information in any manner which may cause loss to the other
        party.
      </p>
      <p>
        A party may disclose, and may permit its Related Persons to disclose,
        any Confidential Information:
      </p>
      <ol style={{ listStyle: "none" }}>
        <li>
          (d) with the prior written consent of the party to whom the
          information relates;
        </li>
        <li>(e) to its officers, employees, consultants and advisers;</li>
        <li>
          (f) as reasonably necessary to perform its obligations under this
          Agreement;
        </li>
        <li>
          (g) to a prospective purchaser, if the prospective purchaser first
          enters a deed under which it agrees to comply with the obligations
          contained in this clause; or
        </li>
        <li>
          (h) to the extent it is required to do so by Law, any order or request
          of any government agency or by any recognised stock exchange on which
          its shares (or those of any of its related bodies corporate) are
          listed;
        </li>
      </ol>
      <p>in each case on a confidential basis.</p>
      <p>
        A party, upon the written request of the other party, must immediately
        deliver to such other party all records and materials that are
        Confidential Information belonging to the other party in its possession
        (or in the possession of a person to whom a party has disclosed it).
      </p>
      <p>This clause survives expiry or termination of this Agreement.</p>
      <h5>9. Compliance with laws</h5>
      <p>
        Each party must comply with applicable laws in performing its
        obligations under this Agreement.
      </p>
      <p>
        The Practice acknowledges and agrees that it is responsible for ensuring
        it has and maintains all necessary consents, permissions and licences
        for all client data and any other data or information included in its
        practice management system and that will be stored, accessed, used and
        otherwise handled by VetXtend in connection with the Services and
        otherwise as contemplated by this Agreement.
      </p>
      <p>
        Without limiting the Practice's obligations under the above clause, the
        Practice must:
      </p>
      <ol style={{ listStyle: "none" }}>
        <li>
          (a) have and maintain consent from the Practice's clients to receiving
          direct marketing communications via email;
        </li>
        <li>
          (b) keep VetXtend informed of any of its clients' withdrawal of
          consent to receive, or to request to unsubscribe from, direct
          marketing communications;
        </li>
        <li>
          (c) notify clients of the Practice's disclosure of their Personal
          Information to third parties (i.e. VetXtend) for use in connection
          with data hosting, data analysis and direct marketing (to ensure
          VetXtend can handle Personal Information for the purposes of this
          Agreement);
        </li>
        <li>
          (d) comply with any other legal obligation it has in connection with
          Privacy Laws and Spam Laws (if those laws apply to the Practice); and
        </li>
        <li>
          (e) comply with all other reasonable directions given, or any policies
          or procedures notified, by VetXtend from time to time.
        </li>
      </ol>
      <h5>10. Security Incident</h5>
      <p>
        In the event the Practice becomes aware of, or suspects, a Security
        Incident, the Practice must immediately notify VetXtend and comply with
        any reasonable direction from VetXtend with respect to the Security
        Incident (which may include, for example, not attempting to access the
        Practice Performance Reporting until the Security Incident is resolved).
        The Practice must cooperate with and provide all assistance reasonably
        requested by VetXtend in connection with a Security Incident. To the
        extent permitted by law, where the Security Incident affects both the
        Practice and VetXtend, the Practice must consult with VetXtend prior to
        making any notifications with respect to such Security Incident.
      </p>
      <p>
        In the event VetXtend becomes aware of, or suspects, a Security
        Incident,VetXtend must immediately notify the Practice and the parties
        will work together in good faith with respect to the Security Incident,
        including VetXtend complying with any reasonable direction from the
        Practice. VetXtend must cooperate with and provide all assistance
        reasonably requested by the Practice in connection with a Security
        Incident.
      </p>
      <p>
        VetXtend warrants to maintain current industry-standard practices for
        safeguarding data and minimising risks of Security Incidents.
      </p>
      <p>
        In these terms, <strong>Security Incident</strong> means a cyber attack,
        data breach, security infringement, breach or approach from any person
        seeking unauthorised access to the Practice's IT environment or
        VetXtend’s IT environment.
      </p>
      <h5>11. Liability</h5>
      <h5>11.1. Limitation of liability</h5>
      <p>
        This clause sets out VetXtend’s entire liability (including any
        liability for the acts or omissions of its employees, agents and
        sub-contractors) to the Practice in respect of all Claims and Loss
        arising under or in connection with this Agreement, whether in contract,
        tort (including negligence but not gross negligence), breach of
        statutory duty, or otherwise.
      </p>
      <p>
        To the fullest extent permitted by law and subject to this clause,
        VetXtend's maximum aggregate liability to the Practice for any Loss
        arising out of or in connection with this Agreement is limited to the
        amounts paid by the Practice under this Agreement in the 12 months
        preceding the last act or omission giving rise to such liability.
      </p>
      <p>
        To the fullest extent permitted by law and subject to this clause, the
        Practice acknowledges and agrees that, to the maximum extent permitted
        by law and subject to this clause, VetXtend will not be liable to the
        Practice for any Claim, Loss or Liability arising from, in connection
        with, or relating in any way to:
      </p>
      <ol style={{ listStyle: "none" }}>
        <li>
          (a) any Loss or inconvenience suffered due to the use, unavailability,
          withdrawal, change, suspension, delay or loss of the Services; or
        </li>
        <li>
          (b) any indirect, incidental, special or consequential loss or damage,
          including loss of business, loss of profit, loss of opportunity,
          business interruption, adverse effect on reputation and/or goodwill or
          lost profits.
        </li>
      </ol>
      <h5>11.2. {country} Consumer Law </h5>
      <p>
        The Practice may have rights under applicable consumer protection law
        with respect to the supply of the Services. This Agreement does not
        exclude or limit any guarantee, condition, warranty, or any other right
        or remedy the Practice may have under applicable consumer protection law
        (Consumer Guarantee). To the extent such Consumer Guarantee cannot be
        excluded, limited or modified at law, any disclaimer, exclusion or
        limitation in this Agreement applies to the fullest extent permitted by
        law and subject to any Consumer Guarantee that cannot be excluded,
        limited or modified at law.
      </p>
      <p>
        Where VetXtend fails to provide the Services in accordance with
        applicable Consumer Guarantees, then to the extent permitted by law and
        subject to the Practice's rights under applicable law, VetXtend 's
        Liability to the Practice will not exceed the cost of re-supplying the
        Services. The Practice may be entitled to seek a refund and in some
        cases compensation under applicable consumer protection law.
      </p>
      <h5>11.3. No limitation or exclusion</h5>
      <p>Nothing in this Agreement limits or excludes VetXtend’s liability:</p>
      <ol style={{ listStyle: "none" }}>
        <li>
          (a) for death or personal injury caused by its or its employees
          negligence;
        </li>
        <li>
          (b) for fraud or fraudulent misrepresentation, wilful misconduct,
          criminal activity or gross negligence by it or its employees, as
          applicable;
        </li>
        <li>
          (c) falling to implement and maintain current industry-standard
          practices for safeguarding data and minimising risks of Security
          Incidents; or
        </li>
        <li>
          (d) where liability cannot be limited or excluded by applicable law.
        </li>
      </ol>
      <h5>12. Indemnity</h5>
      <p>
        The Practice agrees to indemnify VetXtend for any Claim, Loss or
        Liability against or incurred by VetXtend arising from or relating in
        any way to the Services or in connection with this Agreement to the
        extent that such Claim, Loss or Liability was caused or contributed to
        by the Practice.
      </p>
      <h5>13. Disclaimer</h5>
      <p>
        Except as expressly stated in this Agreement, to the maximum extent
        permitted by law, the Services are provided by VetXtend on a best
        endeavours basis and VetXtend expressly disclaims, and excludes all
        Liability arising out of or in connection with, all warranties and
        conditions of any kind, express or Implied, (including without
        limitation any warranty, condition or other implied term as to
        availability, merchantability, non-Infringement, or fitness for a
        particular purpose) and makes no warranty or representation that the
        Services will meet the Practice's requirements or integrate with any
        third party product or service.
      </p>
      <h5>14. Force Majeure</h5>
      <p>
        If, as a result of Force Majeure, any party is prevented in whole or in
        part from carrying out its obligations under this Agreement, it must
        give to the other party prompt notice of the occurrence of that event
        and its effect on that party's ability to fulfil particular obligations
        and take all action reasonably practicable to mitigate any loss suffered
        by the other party as a result of that Force Majeure. The obligations
        under this Agreement of the party giving the notice (excluding any
        obligations to make a payment due hereunder) will be suspended to the
        extent to which they are affected by the relevant Force Majeure event as
        long as the Force Majeure event continues.
      </p>
      <h5>15. Practice business conduct</h5>
      <p>
        The Practice must always act morally and in good faith in relation to
        the conduct of its business, and in all dealings with VetXtend and
        clients.
      </p>
      <p>
        Recommendations for pets should always take a health first approach in
        the best interest of the animal. Any commissions and rebates paid to the
        Practice by VetXtend are not an inducement for specific products, but
        rather provides options for the Practice and its vets with intent to
        support the veterinary industry.
      </p>
      <h5>16. General</h5>
      <p>
        In these Terms & Conditions, capitalised terms have the meaning given to
        them in clause 1 of this Agreement.
      </p>
    </div>
  );
};

const ServiceAggreement: React.VFC<any> = (data) => {
  const { user, isPaying } = data;
  const today =
    user?.subscription_started && !isPaying
      ? new Date(user?.subscription_started)
      : new Date();
  const exportPDF = () => {
    const input = document.getElementById("service-aggreement");
    if (!input) return;
    html2canvas(input).then((canvas: any) => {
      const imgURIpng = canvas.toDataURL("img/png");
      const a = document.createElement("a");
      a.href = imgURIpng; //.replace("image/jpeg", "image/octet-stream");
      a.download = "vetxtend-aggreement.png";
      a.click();
      /*console.log({ h: canvas.height, w: canvas.width });
      const width = 205;
      const height = (canvas.height * width) / canvas.width;
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgURIpng, "PNG", 0, 0, width, height);
      pdf.addPage();
      pdf.save("contract.pdf");*/
    });
  };
  console.log({ user });
  //prettier-ignore
  return (
    <div className="onboarding page">
      <a
        onClick={exportPDF}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          textDecoration: "underline",
          columnGap: "5px"
        }}
      >
        <img src={padlockIcon} />
        Download Agreement
      </a>
      <div id="service-aggreement" style={{ padding: "10px 20px" }}>
        <h4>Reporting and Marketing Services Agreement</h4>
        <h5>Parties</h5>
        <hr style={{ margin: "20px 0" }} />
        <table>
          <tr>
            <th rowSpan={5} style={{ verticalAlign: "top", width: "150px" }}>
              VetXtend
            </th>
            <td style={{ width: "200px" }}>Name</td>
            <td>My Pet PA Pty Ltd trading as VetXtend</td>
          </tr>
          <tr>
            <td>ACN</td>
            <td>649 066 473</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>Unit 12 45-47 Township Drive Burleigh Heads QLD 4220</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>tsteenackers@vetxtend.com.au</td>
          </tr>
          <tr>
            <td>Attention</td>
            <td>Tomas Steenackers</td>
          </tr>
        </table>
        <hr style={{ margin: "20px 0" }} />
        <table>
          <tr>
            <th rowSpan={6} style={{ verticalAlign: "top", width: "150px" }}>
              Practice
            </th>
            <td style={{ width: "200px" }}>Name</td>
            <td>{user?.practice_name}</td>
          </tr>
          <tr>
            <td>Job Title</td>
            <td>{user?.contact_jobtitle}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {user?.address?.address1} {user?.address?.city}
              {""} {user?.address?.country} {user?.address?.postcode}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{user?.email}</td>
          </tr>
          <tr>
            <td>Attention</td>
            <td>{user?.contact_person}</td>
          </tr>
        </table>
        <h5>Background</h5>
        <ol className="letter">
          <li>
            (A) VetXtend provides services, including Practice Performance
            Reporting, digital marketing, and related services to veterinary
            practices.
          </li>
          <li>
            (B) VetXtend also provides an ecommerce platform (Pet PA and Pet PA
            Pharmacy) for the sale and supply of pet products.
          </li>
          <li>
            (C) VetXtend agrees to supply, and the Practice agrees to purchase,
            the Services on the terms and conditions of this document.
          </li>
        </ol>
        <h5>Operative provisions</h5>
        <ol style={{ listStyle: "none" }}>
          <li>
            <h5>1. Definitions and interpretation</h5>
          </li>
          <li>
            <strong>1.1 Definitions apply to Terms & Conditions</strong>
            <p>
              Unless the context otherwise requires, terms defined in this clause
              1 have the same meaning in the Terms & Conditions in Schedule 1.
            </p>
          </li>
          <li>
            <strong>1.2 Definitions</strong>
            <p>
              <strong>Agreement</strong> means this document, the Terms &
              Conditions, and any additional schedules or annexure to this
              document.
            </p>
            <p>
              <strong>Claim</strong> means any claim, action, proceeding or
              demand, however it arises and whether it is present or future, fixed
              or unascertained, actual or contingent.
            </p>
            <p>
              <strong>Commencement Date</strong> has the meaning given to it in
              clause 2.1.
            </p>
            <p>
              <strong>Confidential Information</strong> means any information
              concerning a party or its affairs or business or the affairs or
              business of that party’s customers or clients which is marked
              confidential or which is of a confidential nature and which has been
              or which is made available to the other party, including:
            </p>
            <ol className="letter">
              <li>
                (a) for the Practice, information that has been made available by
                a Practice that is held in a Practice's practice management
                system, and financial Information related to the Practice's
                business held in any finance system used by the Practice for
                preparation of financial accounts; and
              </li>
              <li>
                (b) for VetXtend, processes, marketing or advertising methods,
                technical information, improvements to technical information,
                documents, secret formulae, trade secrets, special knowledge and
                technical know-how,
              </li>
              <li>
                (c) but excluding information already known to the other party or
                information which was publicly available at the time of disclosure
                or which becomes so known from an independent source or to the
                public (other than by breach of this Agreement).
              </li>
            </ol>
            <p>
              <strong>Force Majeure</strong> means any matters beyond the
              reasonable control of the party claiming Force Majeure and includes
              acts of God, war, sabotage, riot, insurrection, civil commotion,
              national emergencies (whether in fact or law) and the effect of any
              applicable laws, orders, rules or regulations.
            </p>
            <p>
              <strong>GST</strong> has the meaning given in the GST Law.
            </p>
            <p>
              <strong>GST Law</strong> has the meaning given in the A New Tax
              System (Goods and Services) Tax Act 1999 (Cth).
            </p>
            <p>
              <strong>Intellectual Property Rights</strong> means all intellectual
              and industrial property rights and interests of whatever nature
              throughout the world conferred under statute, common law or equity,
              and includes rights in respect of, or in connection with, copyright,
              trade marks, service marks, domain names, designs, patents,
              semiconductor and circuit layout rights, rights in confidential
              information, trade secrets, recipes, know-how, business or company
              names, the right to sue for passing off, or other proprietary rights
              (whether registered, unregistered or not registrable) and any rights
              to register such rights, whether now existing or created after the
              date of this Agreement.
            </p>
            <p>
              <strong>Liability</strong> means liabilities, duties and obligations
              of any nature affecting the person concerned, however arising,
              including those which are prospective or contingent and those the
              amount of which for the time being is not ascertained or
              ascertainable.
            </p>
            <p>
              <strong>Loss</strong> means any loss, damage, cost, charge, fees,
              expenses (including reasonable legal costs and expenses) and
              liabilities, however arising, including those that are prospective
              or contingent and those the amount of which is not ascertained or
              ascertainable.
            </p>
            <p>
              <strong>Personal Information</strong> has the meaning given to it
              under the Privacy Act 1988 (Cth).
            </p>
            <p>
              <strong>Practice-Branded Emails</strong> has the meaning given to it
              in clause 3.3.
            </p>
            <p>
              <strong>Privacy Laws</strong> means the Privacy Act 1988 (Cth) and
              any other legislation and binding code governing the handling and
              protection of Personal Information applicable to the respective
              party.
            </p>
            <p>
              <strong>Related Persons</strong> means related bodies corporate and
              their offices, directors, agents, and representatives of a party.
            </p>
            <p>
              <strong>Services</strong> means Practice Performance Reporting and
              associated practice reporting capabilities, client email marketing
              and other services as further described in clause 3.3.
            </p>
            <p>
              <strong>Spam Laws</strong> means the Spam Act 2003 (Cth) and any
              other legislation and binding code governing marketing
              communications applicable to the respective party.
            </p>
            <p>
              <strong>Term</strong> has the meaning given to it in clause 2.1.
            </p>
            <p>
              <strong>Terms & Conditions</strong> means the terms and conditions
              set out in Schedule 1.
            </p>
          </li>
          <li>
            <strong>1.3 Interpretation</strong>
            <p>In this Agreement, unless the contrary intention appears:</p>
            <ol className="letter">
              <li>
                (a) the singular includes the plural and vice versa, and a gender
                includes other genders;
              </li>
              <li>
                (b) ‘includes’ and ‘including’ mean includes without limitation;
              </li>
              <li>
                (c) another grammatical form of a defined word or expression has a
                corresponding meaning;
              </li>
              <li>
                (d) a reference to a document or instrument includes the document
                or instrument as novated, altered, supplemented or replaced from
                time to time;
              </li>
              <li>
                (e) a reference to a party includes its legal personal
                representatives, successors and permitted assigns;
              </li>
              <li>
                (f) a reference to A$, $A, dollar or $ is to Australian currency;
              </li>
              <li>(g) a reference to time is to Brisbane, Australia time;</li>
              <li>
                (h) if a day on or by which an obligation must be performed or an
                event must occur is not a Business Day, the obligation must be
                performed or the event must occur on or by the next Business Day;
              </li>
              <li>
                (i) a reference to a party is to a party to this deed, and a
                reference to a party to a document includes the party's executors,
                administrators, successors and permitted assigns and substitutes;
              </li>
              <li>
                (j) a reference to a person includes a natural person,
                partnership, body corporate, association, governmental or local
                authority or agency or other entity;
              </li>
              <li>
                (k) a rule of construction does not apply to the disadvantage of a
                party because the party was responsible for the preparation of
                this deed or any part of it; and
              </li>
              <li>
                (l) unless expressly stated to be otherwise, the meaning of
                general words is not limited by specific examples introduced by
                ‘including’, ‘for example’ or similar inclusive expressions.
              </li>
            </ol>
          </li>
          <li>
            <strong>1.4 Headings</strong>
            <p>
              Headings are for ease of reference only and do not affect
              interpretation.
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>2. Term and Terms & Conditions</h5>
            <strong>2.1 Term</strong>
            <ol className="letter">
              <li>
                (a) This Agreement commences on {""}
                <strong>
                  {months.find((f, k) => k == today.getMonth())} {today.getDate()}
                  , {""} {today.getFullYear()} {""}
                </strong>
                and continues until terminated in accordance with the Terms &
                Conditions.
              </li>
              <li>
                (b) VetXtend will commence supply of the Services on and from the
                Commencement Date or such later date as agreed between the parties
                in writing.
              </li>
            </ol>
            <strong>2.2 Terms & Conditions</strong>
            <ol className="letter">
              <li>
                (a) The parties agree that the Terms & Conditions (as updated from
                time to time) apply to the arrangement between the parties in
                relation to the Services and associated documents, including
                VetXtend onboarding forms completed.
              </li>
              <li>
                (b) VetXtend will give the Practice no less than 30 days’ notice
                in writing of any changes it makes to the Terms & Conditions.
                VetXtend will only make changes in limited circumstances and at
                all times acting reasonably. If the Practice does not accept such
                changes, then it may give VetXtend 30 days’ notice in writing to
                terminate this agreement. If the Practice does not notify VetXtend
                of termination under this clause within 15 days of the date of
                VetXtend’s notice, the Practice will be deemed to have accepted
                the changes to the Terms & Conditions.
              </li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>3. Services</h5>
            <strong>3.1 Non-Exclusive</strong>
            <p style={{ paddingLeft: "20px" }}>
              The arrangement between the parties in relation to the Services is
              not an exclusive arrangement, and VetXtend may provide similar
              services to other businesses.
            </p>
            <strong>3.2 Provision of Services</strong>
            <p style={{ paddingLeft: "20px" }}>
              VetXtend will provide the Services to the Practice using all
              reasonable endeavours from the data and information provided to it
              by the Practice.
            </p>
            <strong>3.3 VetXtend Service Offering</strong>
            <ol className="letter">
              <li>
                (a) VetXtend offers a range of its Services based on partnership
                levels as set out on its website from time to time at
                vetxtend.com.au.
              </li>
              <li>
                (b) The Practice will confirm, by completion of VetXtend
                onboarding forms, which partnership level (and Services) it wishes
                to purchase.
              </li>
              <li>
                (c) VetXtend Services are as follows:
                <table className="bordered-table">
                  <tr>
                    <td style={{ width: "20%" }}>Partnership Level</td>
                    <td style={{ width: "20%" }}>Fee (excl GST)</td>
                    <td style={{ width: "20%" }}>
                      Partnership Program & Rebates
                    </td>
                    <td style={{ width: "20%" }}>
                      Practice Performance Reporting
                    </td>
                    <td style={{ width: "20%" }}>Digital Marketing Services</td>
                  </tr>
                  <tr>
                    <td>VetXpress</td>
                    <td>$299 annually</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>VetXplore</td>
                    <td>$250 per month</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>VetXpand</td>
                    <td>$500 annually</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                  </tr>
                </table>
                The services in the above table are further explained as follows:
                <table className="bordered-table">
                  <tr>
                    <td style={{ width: "20%" }}>Service</td>
                    <td style={{ width: "80%" }}>Details of Service</td>
                  </tr>
                  <tr>
                    <td>Partnership Program & Rebates</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Supply of unique link and QR code, along with some
                          promotional materials to use in practice.
                        </li>
                        <li>
                          2. Payment monthly of commission on sales of products
                          made through a unique link (and tracked through an
                          affiliate software application) provided on practice
                          marketing materials.
                        </li>
                        <li>
                          3. Payment monthly of rebates for referrals of pet
                          medication scripts to Pet PA Pharmacy for the Practice’s
                          clients’ pets (tracked by tagging the issuing Practice
                          in VetXtend systems).
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Practice Performance Reporting</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Establishment of Practice Performance Reporting for
                          the Practice, integrated to its practice management
                          system and finance system.
                        </li>
                        <li>
                          2. Standard reporting dashboards suite (as set out on
                          the VetXtend website at Partnership Levels - Vet Extend
                          (vetxtend.com.au)) is included as part of the Practice
                          Performance Reporting.
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Digital Marketing Service</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Complimentary Education and Retail Communications
                          service with additional add-on marketing service bundles
                          for additional fees. Details and costs of additional
                          add-on marketing bundles are set out on the VetXtend
                          website at Partnership Levels - Vet Extend
                          (vetxtend.com.au) and include NPS surveys and targeted/
                          customised client communications.
                        </li>
                      </ol>
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                (d) If the Practice selects the VetXpand partnership level, within
                three (3) months of Commencement Date, the Practice will either:
                <ol className="letter">
                  <li>
                    (i) establish a client communication strategy with VetXtend
                    where VetXtend will send Practice branded email communications
                    (in the form and frequency as agreed between the parties in
                    advance) to the Practice’s clients in relation to pet care
                    educational content and the promotion of pet care products
                    from VetXtend’s ecommerce site (Practice-branded Emails);
                  </li>
                  <li>
                    (ii) use and/or promote to clients (as applicable) at least
                    two (2) services provided by the VetXtend business, e.g.
                    wellness plans, Pet PA Pharmacy or marketing services;
                  </li>
                  <li>(iii) change to the VetXplore partnership level; or</li>
                  <li>(iv) terminate the Services.</li>
                </ol>
              </li>
              <li>
                (e) VetXtend will not directly market to the Practice’s clients
                without the Practice’s consent (unless a client is an existing
                VetXtend or Pet PA customer or otherwise purchases products from
                VetXtend independently of this Agreement).
              </li>
              <li>
                (f) Practice Performance Reporting services provided by VetXtend
                may require the Practice to have access to Microsoft Power BI Pro,
                in which case the Practice will procure and pay for a licence
                directly with Microsoft for Power BI Pro (Pricing & Product
                Comparison | Microsoft Power BI).
              </li>
            </ol>
            <strong>3.4 Payment of Commission and Rebates</strong>
            <p style={{ paddingLeft: "20px" }}>
              VetXtend will pay commissions and rebates to the Practice as
              follows:
            </p>
            <ol className="letter">
              <li>
                (a) Commission rates and rebate amounts will be advised on
                commencement of this Agreement;
              </li>
              <li>
                (b) Commission and rebates will be paid monthly into the
                Practice’s nominated bank account (amounts paid will include GST);
              </li>
              <li>
                (c) Commission and rebates payable for a calendar month will be
                paid by the 15th of the following month; and
              </li>
              <li>
                (d) The Practice will receive monthly statements and will also
                have access to sales and commission reports daily.
              </li>
            </ol>
            <strong>3.5 VetXtend Support</strong>
            <p style={{ paddingLeft: "20px" }}>
              VetXtend will provide support to the Practice in relation to the
              Services during the Term of the Agreement on
              support@vetxtend.com.au.
            </p>
            <strong>3.6 Change between Services</strong>
            <ol className="letter">
              <li>
                (g) The Practice may change between Services (increase or decrease
                in Services by changing partnership levels or start new services)
                by giving written notice to VetXtend.
              </li>
              <li>
                (h) The fees for, and obligations and benefits arising from, the
                new level of Services or new services will apply and be charged to
                the Practice (as applicable) from when the notice has been
                received, or such other time agreed between the parties (for
                example, the first day of the following calendar month).
              </li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>4. Fees</h5>
            <p>
              The fees set out in clause 3.3 are payable by the Practice in
              advance in immediately available funds by a payment method agreed
              between the parties. VetXtend will provide a tax invoice for the
              fees as required.
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>5. Warranties</h5>
            <p>
              Each party makes the following representations and warranties to the
              other party, each of which is true and correct on the date of this
              Agreement:
            </p>
            <ol className="letter">
              <li>
                (a) it has been validly incorporated under the laws of the country
                of its incorporation;
              </li>
              <li>
                (b) this Agreement and the transactions under it do not contravene
                its constituent documents or any law or obligation by which it is
                bound or to which any of its assets are subject;
              </li>
              <li>
                (c) it has power to enter into this Agreement and comply with its
                obligations under it;
              </li>
              <li>
                (d) its obligations under this Agreement are valid and binding and
                are enforceable against it in accordance with the terms of this
                Agreement; and
              </li>
              <li>(e) it is not insolvent.</li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>6. GST</h5>
            <strong>6.1 Consideration GST exclusive</strong>
            <p>
              Unless otherwise expressly stated, all amounts payable under this
              document are expressed to be exclusive of GST.
            </p>
            <strong>6.2 Payment of GST</strong>
            <ol className="letter">
              <li>
                (e) If GST is payable on a supply made under or in connection with
                this document, for which the consideration is not expressly stated
                to be GST inclusive, the party providing the consideration for
                that supply must pay to the supplier as additional consideration
                an amount equal to the amount of GST payable on that supply (the
                GST Amount).
              </li>
              <li>
                (f) The GST Amount is payable at the same time that the other
                consideration for the supply is provided, except that the GST
                Amount need not be paid until the supplier gives a tax invoice to
                the recipient of the supply.
              </li>
            </ol>
            <strong>6.3 Adjustment Events</strong>
            <p>
              Whenever an adjustment event occurs in relation to any supply made
              under or in connection with this document, the GST Amount will be
              recalculated to reflect that adjustment and the amount of the
              difference from the GST Amount must be paid by, refunded to or
              credited to the recipient of the supply, as applicable.
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>7. Notices</h5>
            <strong>7.1 Giving Notice</strong>
            <p>
              Any notice, consent, approval or other communication (a Notice)
              given or made under or in connection with this Agreement must be
              delivered to the intended recipient by hand, email or prepaid post
              (airmail if applicable) to the address or email address set out at
              the start of this Agreement or the address or email address last
              notified in writing by the intended recipient to the sender
              (including in the onboarding forms completed by the Practice).
            </p>
            <strong>7.2 When Notice is Effective</strong>
            <p>
              A Notice will be effective upon receipt and will be taken to be
              received:
            </p>
            <ol className="letter">
              <li>(g) in the case of delivery by hand, when delivered;</li>
              <li>
                (h) in the case of delivery by post, five Business Days after the
                date of posting (or fifteen Business Days after the date of
                posting if sent from one country to another); and
              </li>
              <li>
                (i) in the case of email, the earlier of:
                <ol className="letter">
                  <li>
                    (i) at the time the sender received an automated message
                    confirming delivery;
                  </li>
                  <li>
                    (ii) at the time the intended recipient confirms delivery by
                    reply email; and
                  </li>
                  <li>
                    (iii) one hour after the time the email is sent (as recorded
                    on the device from which the sender sent the email) unless the
                    sender receives an automated delivery failure notification
                    indicating that the email has not been delivered,
                  </li>
                </ol>
                but if the result is that a Notice is received or taken to be
                received outside the period between 9.00am and 5.00pm on a
                Business Day in the place of the addressee’s postal address for
                Notices, then the Notice will be taken to be received at 9.00am on
                the following Business Day in that place.
              </li>
            </ol>
          </li>
          <li>
            <h5>8. General</h5>
            <strong>8.1 Entire understanding</strong>
            <p>
              This Agreement contains the entire understanding of the parties with
              reference to the subject matter and there is no other understanding
              agreement warranty or representation whether express or implied in
              any way extending defining or otherwise relating to the provisions
              of this Agreement or binding on the parties with respect to any of
              the matters to which this Agreement relates.
            </p>
            <strong>8.2 No Assignment</strong>
            <p>
              The rights and obligations of each party under this Agreement are
              personal and may not be assigned by either party without the prior
              written consent of the other party.
            </p>
            <strong>8.3 Amendment</strong>
            <p>
              This Agreement (except for the Terms & Conditions in accordance with
              clause 2.2(b)) may be amended only by written agreement executed by
              all parties.
            </p>
            <strong>8.4 Invalidity</strong>
            <p>
              Invalidity of any provisions in this Agreement does not affect any
              other provision.
            </p>
            <strong>8.5 Waiver, Rights and Remedies</strong>
            <p>
              Any delay or failure by VetXtend to enforce any right, power or
              remedy under this Agreement does not constitute waiver, and the
              single or partial exercise or waiver of the exercise of any right,
              power or remedy does not preclude any other or further exercise of
              that or any other right, power or remedy.
            </p>
            <strong>8.6 Order of precedence</strong>
            <p>
              To the extent of any inconsistency between this document, the Terms
              & Conditions and any other schedule to this Agreement, the
              inconsistency is resolved in the preceding order of precedence, to
              the extent of such inconsistency.
            </p>
            <strong>8.7 Governing Law and jurisdiction</strong>
            <p>
              This Agreement is governed by and construed in accordance with the
              Laws of Queensland, Australia. Each party irrevocably submits to the
              non-exclusive jurisdiction of the courts of Queensland and any court
              of competent jurisdiction to hear appeals from those courts.
            </p>
          </li>
        </ol>
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              <h4>Executed as an agreement</h4>
              <p>
                EXECUTED by <strong>My Pet PA Pty Ltd ACN 649 066 473</strong>
              </p>
              <br />
              <p
                style={{
                  fontFamily: "cursive",
                  fontStyle: "italic",
                  fontSize: "30px",
                  letterSpacing: "-1px",
                }}
              >
                Tomas Steenackers
              </p>
              <p>
                Signature of <strong>Tomas Steenackers</strong> as Sole
                Director/Company Secretary
              </p>
            </td>
            <td style={{ width: "50%" }}></td>
          </tr>
        </table>
      </div>
    </div>
  );
  //prettier-ignore-end
};

const ServiceAggreementNZ: React.VFC<any> = (data) => {
  const { user, isPaying } = data;
  const today =
    user?.subscription_started && !isPaying
      ? new Date(user?.subscription_started)
      : new Date();
  const exportPDF = () => {
    const input = document.getElementById("service-aggreement");
    if (!input) return;
    html2canvas(input).then((canvas: any) => {
      const imgURIpng = canvas.toDataURL("img/png");
      const a = document.createElement("a");
      a.href = imgURIpng; //.replace("image/jpeg", "image/octet-stream");
      a.download = "vetxtend-aggreement-nz.png";
      a.click();
    });
  };
  //prettier-ignore
  return (
    <div className="onboarding page">
      <a
        onClick={exportPDF}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          textDecoration: "underline",
          columnGap: "5px"
        }}
      >
        <img src={padlockIcon} />
        Download Agreement
      </a>
      <div id="service-aggreement" style={{ padding: "10px 20px" }}>
        <h4>Reporting and Marketing Services Agreement</h4>
        <h5>Parties</h5>
        <hr style={{ margin: "20px 0" }} />
        <table>
          <tr>
            <th rowSpan={5} style={{ verticalAlign: "top", width: "150px" }}>
              VetXtend
            </th>
            <td style={{ width: "200px" }}>Name</td>
            <td>My Pet PA Pty Ltd trading as VetXtend</td>
          </tr>
          <tr>
            <td>ACN</td>
            <td>649 066 473</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>Unit 12 45-47 Township Drive Burleigh Heads QLD 4220</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>tsteenackers@vetxtend.com.au</td>
          </tr>
          <tr>
            <td>Attention</td>
            <td>Tomas Steenackers</td>
          </tr>
        </table>
        <hr style={{ margin: "20px 0" }} />
        <table>
          <tr>
            <th rowSpan={6} style={{ verticalAlign: "top", width: "150px" }}>
              Practice
            </th>
            <td style={{ width: "200px" }}>Name</td>
            <td>{user?.practice_name}</td>
          </tr>
          <tr>
            <td>Job Title</td>
            <td>{user?.contact_jobtitle}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {user?.address?.address1} {user?.address?.city}
              {""} {user?.address?.country} {user?.address?.postcode}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{user?.email}</td>
          </tr>
          <tr>
            <td>Attention</td>
            <td>{user?.contact_person}</td>
          </tr>
        </table>
        <h5>Background</h5>
        <ol className="letter">
          <li>
            (A) VetXtend provides services, including Practice Performance Reporting,
            digital marketing, and related services to veterinary practices. 
          </li>
          <li>
            (B) VetXtend also provides an ecommerce platform (Pet PA) for the sale
            and supply of pet products and pet medication. 
          </li>
          <li>
            (C) VetXtend agrees to supply, and the Practice agrees to purchase,
            the Services on the terms and conditions of this document.  
          </li>
        </ol>
        <h5>Operative provisions</h5>
        <ol style={{ listStyle: "none" }}>
          <li>
            <h5>1. Definitions and interpretation</h5>
          </li>
          <li>
            <strong>1.1 Definitions apply to Terms & Conditions</strong>
            <p>
              Unless the context otherwise requires, terms defined in this clause 1
              have the same meaning in the Terms & Conditions in Schedule 1. 
            </p>
          </li>
          <li>
            <strong>1.2 Definitions</strong>
            <p>
              <strong>Agreement</strong> means this document, the Terms
              & Conditions, and any additional schedules or annexure to this document. 
            </p>
            <p>
              <strong>Claim</strong> means any claim, action, proceeding or demand, however it arises and whether
              it is present or future, fixed or unascertained, actual or contingent. 
            </p>
            <p>
              <strong>Commencement Date</strong> has the meaning given to it in clause 2.1. 
            </p>
            <p>
              <strong>Confidential Information</strong>
              means any information concerning a party or its affairs or business
              or the affairs or business of that party’s customers or clients which
              is marked confidential or which is of a confidential nature and which has
              been or which is made available to the other party, including: 
            </p>
            <ol className="letter">
              <li>
                (a) for the Practice, information that has been made available by a Practice that
                is held in a Practice's practice management system, and financial Information related
                to the Practice's business held in any finance system used by the Practice for preparation
                of financial accounts; and
              </li>
              <li>
                (b) for VetXtend, processes, marketing or advertising methods, technical information,
                improvements to technical information, documents, secret formulae, trade secrets,
                special knowledge and technical know-how, 
              </li>
              <li>
                (c) but excluding information already known to the other party or information which
                was publicly available at the time of disclosure or which becomes so known from an
                independent source or to the public (other than by breach of this Agreement).
              </li>
            </ol>
            <p>
              <strong>Force Majeure</strong> means any matters beyond the reasonable control of
              the party claiming Force Majeure and includes acts of God, war, sabotage, riot, insurrection,
              civil commotion, national emergencies (whether in fact or law) and the effect of any applicable
              laws, orders, rules or regulations. 
            </p>
            <p>
              <strong>GST</strong> has the meaning given in the GST Law.
            </p>
            <p>
              <strong>GST Law</strong> has the meaning given in the Goods and Services Tax Act 1985. 
            </p>
            <p>
              <strong>Intellectual Property Rights</strong> means all intellectual and industrial property
              rights and interests of whatever nature throughout the world conferred under statute, common
              law or equity, and includes rights in respect of, or in connection with, copyright, trade marks,
              service marks, domain names, designs, patents, semiconductor and circuit layout rights, rights
              in confidential information, trade secrets, recipes, know-how, business or company names, the
              right to sue for passing off, or other proprietary rights (whether registered, unregistered or
              not registrable) and any rights to register such rights, whether now existing or created after
              the date of this Agreement. 
            </p>
            <p>
              <strong>Liability</strong> means liabilities, duties and obligations of any nature affecting
              the person concerned, however arising, including those which are prospective or contingent
              and those the amount of which for the time being is not ascertained or ascertainable.  
            </p>
            <p>
              <strong>Loss</strong> means any loss, damage, cost, charge, fees, expenses
              (including reasonable legal costs and expenses) and liabilities, however arising, including
              those that are prospective or contingent and those the amount of which is not
              ascertained or ascertainable.
            </p>
            <p>
              <strong>Personal Information</strong> has the meaning given to it under the Privacy Act 1988 (Cth).
            </p>
            <p>
              <strong>Practice-Branded Emails</strong> has the meaning given to it in clause 3.3. 
            </p>
            <p>
              <strong>Privacy Laws</strong> means the Privacy Act 2020 and any other legislation and
              binding code governing the handling and protection of Personal Information applicable to
              the respective party. 
            </p>
            <p>
              <strong>Related Persons</strong> means related bodies corporate and their offices, directors,
              agents, and representatives of a party. 
            </p>
            <p>
              <strong>Services</strong> means Practice Performance Reporting and associated practice reporting capabilities, client email marketing and other services as further described in clause 3.3. 
            </p>
            <p>
              <strong>Spam Laws</strong> means the Unsolicited Electronic Messages Act 2007 and any other legislation and binding code governing marketing communications applicable to the respective party. 
            </p>
            <p>
              <strong>Term</strong> has the meaning given to it in clause 2.1.  
            </p>
            <p>
              <strong>Terms & Conditions</strong> means the terms and conditions set out in Schedule 1. 
            </p>
          </li>
          <li>
            <strong>1.3 Interpretation</strong>
            <p>In this Agreement, unless the contrary intention appears: </p>
            <ol className="letter">
              <li>
                (a) the singular includes the plural and vice versa, and a gender includes other genders; 
              </li>
              <li>
                (b) ‘includes’ and ‘including’ mean includes without limitation; 
              </li>
              <li>
                (c) another grammatical form of a defined word or expression has a corresponding meaning; 
              </li>
              <li>
                (d) a reference to a document or instrument includes the document or instrument as novated, altered, supplemented or replaced from time to time; 
              </li>
              <li>
                (e) a reference to a party includes its legal personal representatives, successors and permitted assigns; 
              </li>
              <li>
                (f) a reference to NZ$, $NZ, dollar or $ is to New Zealand currency;
              </li>
              <li>
                (g) a reference to time is to Auckland, New Zealand time;  </li>
              <li>
                (h) if a day on or by which an obligation must be performed or an event must occur is not a Business Day, the obligation must be performed or the event must occur on or by the next Business Day;
              </li>
              <li>
                (i) a reference to a party is to a party to this deed, and a reference to a party to a document includes the party's executors, administrators, successors and permitted assigns and substitutes; 
              </li>
              <li>
                (j) a reference to a person includes a natural person, partnership, body corporate, association, governmental or local authority or agency or other entity; 
              </li>
              <li>
                (k) a rule of construction does not apply to the disadvantage of a party because the party was responsible for the preparation of this deed or any part of it; and  
              </li>
              <li>
                (l) unless expressly stated to be otherwise, the meaning of general words is not limited by specific examples introduced by ‘including’, ‘for example’ or similar inclusive expressions. 
              </li>
            </ol>
          </li>
          <li>
            <strong>1.4 Headings</strong>
            <p>
              Headings are for ease of reference only and do not affect interpretation. 
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>2. Term and Terms & Conditions</h5>
            <strong>2.1 Term</strong>
            <ol className="letter">
              <li>
                (a) This Agreement commences on {""}
                <strong>
                  {months.find((f, k) => k == today.getMonth())} {today.getDate()}
                  , {""} {today.getFullYear()} {""}
                </strong>
                and continues until terminated in accordance with the Terms & Conditions.  
              </li>
              <li>
                (b) VetXtend will commence supply of the Services on and from the Commencement Date or such later date as agreed between the parties in writing. 
              </li>
            </ol>
            <strong>2.2 Terms & Conditions</strong>
            <ol className="letter">
              <li>
                (a) The parties agree that the Terms & Conditions (as updated from time to time) apply to the arrangement between the parties in relation to the Services and associated documents, including VetXtend onboarding forms completed.  
              </li>
              <li>
                (b) VetXtend will give the Practice no less than 30 days’ notice in writing of any changes it makes to the Terms & Conditions.  VetXtend will only make changes in limited circumstances and at all times acting reasonably. If the Practice does not accept such changes, then it may give VetXtend 30 days’ notice in writing to terminate this agreement. If the Practice does not notify VetXtend of termination under this clause within 15 days of the date of VetXtend’s notice, the Practice will be deemed to have accepted the changes to the Terms & Conditions.
              </li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>3. Services</h5>
            <strong>3.1 Non-Exclusive</strong>
            <p style={{ paddingLeft: "20px" }}>
              The arrangement between the parties in relation to the Services is not an exclusive arrangement, and VetXtend may provide similar services to other businesses.  
            </p>
            <strong>3.2 Provision of Services</strong>
            <p style={{ paddingLeft: "20px" }}>
              VetXtend will provide the Services to the Practice using all reasonable endeavours from the data and information provided to it by the Practice. 
            </p>
            <strong>3.3 VetXtend Service Offering</strong>
            <ol className="letter">
              <li>
                (a) VetXtend offers a range of its Services based on partnership levels as set out on its website from time to time at vetxtend.com.au. 
              </li>
              <li>
                (b) The Practice will confirm, by completion of VetXtend onboarding forms, which partnership level (and Services) it wishes to purchase. 
              </li>
              <li>
                (c) VetXtend Services are as follows:  
                <table className="bordered-table">
                  <tr>
                    <td style={{ width: "20%" }}>Partnership Level</td>
                    <td style={{ width: "20%" }}>Fee (excl GST)</td>
                    <td style={{ width: "20%" }}>
                      Partnership Program & Rebates
                    </td>
                    <td style={{ width: "20%" }}>
                      Practice Performance Reporting
                    </td>
                    <td style={{ width: "20%" }}>Digital Marketing Services</td>
                  </tr>
                  <tr>
                    <td>VetXpress</td>
                    <td>$299 annually</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>VetXplore</td>
                    <td>$250 per month</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>VetXpand</td>
                    <td>$500 annually</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                    <td style={{ textAlign: "center" }}>✔</td>
                  </tr>
                </table>
                The services in the above table are further explained as follows:
                <table className="bordered-table">
                  <tr>
                    <td style={{ width: "20%" }}>Service</td>
                    <td style={{ width: "80%" }}>Details of Service</td>
                  </tr>
                  <tr>
                    <td>Partnership Program & Rebates</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Supply of unique link and QR code, along with some promotional materials to use in practice. 
                        </li>
                        <li>
                          2. Payment monthly of commission on sales of products made through a unique link (and tracked through an affiliate software application) provided on practice marketing materials. 
                        </li>
                        <li>
                          3. Payment monthly of rebates for referrals of pet medication scripts to Pet PA Pharmacy for the Practice’s clients’ pets (tracked by tagging the issuing Practice in VetXtend systems).
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Practice Performance Reporting</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Establishment of Practice Performance Reporting for the Practice, integrated to its practice management system and finance system. 
                        </li>
                        <li>
                          2. Standard reporting dashboards suite (as set out on the VetXtend website at Partnership Levels - Vet Extend (vetxtend.co.nz)) is included as part of the Practice Performance Reporting.
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>Digital Marketing Service</td>
                    <td>
                      <ol className="letter">
                        <li>
                          1. Complimentary Education and Retail Communications service with additional add-on marketing service bundles for additional fees.  
                          <br />
                          Details and costs of additional add-on marketing bundles are set out on the VetXtend website at Partnership Levels - Vet Extend (vetxtend.co.nz) and include NPS surveys and targeted/ customised client communications.
                        </li>
                      </ol>
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                (d) If the Practice selects the VetXpand partnership level, within three (3) months of Commencement Date, the Practice will either: 
                <ol className="letter">
                  <li>
                    (i) establish a client communication strategy with VetXtend where VetXtend will send Practice branded email communications (in the form and frequency as agreed between the parties in advance) to the Practice’s clients in relation to pet care educational content and the promotion of pet care products from VetXtend’s ecommerce site (Practice-branded Emails); 
                  </li>
                  <li>
                    (ii) use and/or promote to clients (as applicable) at least two (2) services provided by the VetXtend business, e.g. wellness plans, pharmacy or marketing services; 
                  </li>
                  <li>(iii) change to the VetXplore partnership level; or </li>
                  <li>(iv) terminate the Services.</li>
                </ol>
              </li>
              <li>
                (e) VetXtend will not directly market to the Practice’s clients without the Practice’s consent (unless a client is an existing VetXtend or Pet PA customer or otherwise purchases products from VetXtend independently of this Agreement). 
              </li>
              <li>
                (f) Practice Performance Reporting services provided by VetXtend may require the Practice to have access to Microsoft Power BI Pro, in which case the Practice will procure and pay for a licence directly with Microsoft for Power BI Pro (Pricing & Product Comparison | Microsoft Power BI). 
              </li>
            </ol>
            <strong>3.4 Payment of Commission and Rebates</strong>
            <p style={{ paddingLeft: "20px" }}>
            VetXtend will pay commissions and rebates to the Practice as follows:  
            </p>
            <ol className="letter">
              <li>
                (a) Commission rates and rebate amounts will be advised on commencement of this Agreement; 
              </li>
              <li>
                (b) Commission and rebates will be paid monthly into the Practice’s nominated bank account (amounts paid will include GST); 
              </li>
              <li>
                (c) Commission and rebates payable for a calendar month will be paid by the 15th of the following month; and  
              </li>
              <li>
                (d) The Practice will receive monthly statements and will also have access to sales and commission reports daily. 
              </li>
            </ol>
            <strong>3.5 VetXtend Support</strong>
            <p style={{ paddingLeft: "20px" }}>
            VetXtend will provide support to the Practice in relation to the Services during the Term of the Agreement on info@vetxtend.co.nz.
            </p>
            <strong>3.6 Change between Services</strong>
            <ol className="letter">
              <li>
                (g) The Practice may change between Services (increase or decrease in Services by changing partnership levels or start new services) by giving written notice to VetXtend.
              </li>
              <li>
                (h) The fees for, and obligations and benefits arising from, the new level of Services or new services will apply and be charged to the Practice (as applicable) from when the notice has been received, or such other time agreed between the parties (for example, the first day of the following calendar month). 
              </li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>4. Fees</h5>
            <p>
              The fees set out in clause 3.3 are payable by the Practice in advance in immediately available funds by a payment method agreed between the parties.  VetXtend will provide a tax invoice for the fees as required. 
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>5. Warranties</h5>
            <p>
              Each party makes the following representations and warranties to the other party, each of which is true and correct on the date of this Agreement: 
            </p>
            <ol className="letter">
              <li>
                (a) it has been validly incorporated under the laws of the country of its incorporation; 
              </li>
              <li>
                (b) this Agreement and the transactions under it do not contravene its constituent documents or any law or obligation by which it is bound or to which any of its assets are subject; 
              </li>
              <li>
                (c) it has power to enter into this Agreement and comply with its obligations under it; 
              </li>
              <li>
                (d) its obligations under this Agreement are valid and binding and are enforceable against it in accordance with the terms of this Agreement; and 
              </li>
              <li>(e) it is not insolvent.</li>
            </ol>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>6. GST</h5>
            <strong>6.1 Consideration GST exclusive</strong>
            <p>
              Unless otherwise expressly stated, all amounts payable under this document are expressed to be exclusive of GST.
            </p>
            <strong>6.2 Payment of GST</strong>
            <ol className="letter">
              <li>
                (e) If GST is payable on a supply made under or in connection with this document, for which the consideration is not expressly stated to be GST inclusive, the party providing the consideration for that supply must pay to the supplier as additional consideration an amount equal to the amount of GST payable on that supply (the GST Amount). 
              </li>
              <li>
                (f) The GST Amount is payable at the same time that the other consideration for the supply is provided, except that the GST Amount need not be paid until the supplier gives a tax invoice to the recipient of the supply. 
              </li>
            </ol>
            <strong>6.3 Adjustment Events</strong>
            <p>
              Whenever an adjustment event occurs in relation to any supply made under or in connection with this document, the GST Amount will be recalculated to reflect that adjustment and the amount of the difference from the GST Amount must be paid by, refunded to or credited to the recipient of the supply, as applicable. 
            </p>
            <hr style={{ margin: "20px 0" }} />
          </li>
          <li>
            <h5>7. Notices</h5>
            <strong>7.1 Giving Notice</strong>
            <p>
            Any notice, consent, approval or other communication (a Notice) given or made under or in connection with this Agreement must be delivered to the intended recipient by hand, email or prepaid post (airmail if applicable) to the address or email address set out at the start of this Agreement or the address or email address last notified in writing by the intended recipient to the sender (including in the onboarding forms completed by the Practice). 
            </p>
            <strong>7.2 When Notice is Effective</strong>
            <p>
            A Notice will be effective upon receipt and will be taken to be received: 
            </p>
            <ol className="letter">
              <li>(g) in the case of delivery by hand, when delivered; </li>
              <li>
                (h) in the case of delivery by post, five Business Days after the date of posting (or fifteen Business Days after the date of posting if sent from one country to another); and
              </li>
              <li>
                (i) in the case of email, the earlier of:
                <ol className="letter">
                  <li>
                    (i) at the time the sender received an automated message confirming delivery; 
                  </li>
                  <li>
                    (ii) at the time the intended recipient confirms delivery by reply email; and 
                  </li>
                  <li>
                    (iii) one hour after the time the email is sent (as recorded on the device from which the sender sent the email) unless the sender receives an automated delivery failure notification indicating that the email has not been delivered, 
                  </li>
                </ol>
                but if the result is that a Notice is received or taken to be received outside the period between 9.00am and 5.00pm on a Business Day in the place of the addressee’s postal address for Notices, then the Notice will be taken to be received at 9.00am on the following Business Day in that place. 
              </li>
            </ol>
          </li>
          <li>
            <h5>8. General</h5>
            <strong>8.1 Entire understanding</strong>
            <p>
            This Agreement contains the entire understanding of the parties with reference to the subject matter and there is no other understanding agreement warranty or representation whether express or implied in any way extending defining or otherwise relating to the provisions of this Agreement or binding on the parties with respect to any of the matters to which this Agreement relates. 
            </p>
            <strong>8.2 No Assignment</strong>
            <p>
            The rights and obligations of each party under this Agreement are personal and may not be assigned by either party without the prior written consent of the other party. 
            </p>
            <strong>8.3 Amendment</strong>
            <p>
            This Agreement (except for the Terms & Conditions in accordance with clause 2.2(b)) may be amended only by written agreement executed by all parties. 
            </p>
            <strong>8.4 Invalidity</strong>
            <p>
            Invalidity of any provisions in this Agreement does not affect any other provision.  
            </p>
            <strong>8.5 Waiver, Rights and Remedies</strong>
            <p>
            Any delay or failure by VetXtend to enforce any right, power or remedy under this Agreement does not constitute waiver, and the single or partial exercise or waiver of the exercise of any right, power or remedy does not preclude any other or further exercise of that or any other right, power or remedy. 
            </p>
            <strong>8.6 Order of precedence</strong>
            <p>
            To the extent of any inconsistency between this document, the Terms & Conditions and any other schedule to this Agreement, the inconsistency is resolved in the preceding order of precedence, to the extent of such inconsistency. 
            </p>
            <strong>8.7 Governing Law and jurisdiction</strong>
            <p>
            This Agreement is governed by and construed in accordance with the law of New Zealand.  Each party irrevocably submits to the non-exclusive jurisdiction of the courts of New Zealand and any court of competent jurisdiction to hear appeals from those courts. 
            </p>
          </li>
        </ol>
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              <h4>Executed as an agreement</h4>
              <p>
                EXECUTED by <strong>My Pet PA Pty Ltd ACN 649 066 473</strong>
              </p>
              <br />
              <p
                style={{
                  fontFamily: "cursive",
                  fontStyle: "italic",
                  fontSize: "30px",
                  letterSpacing: "-1px",
                }}
              >
                Tomas Steenackers
              </p>
              <p>
                Signature of <strong>Tomas Steenackers</strong> as Sole
                Director/Company Secretary
              </p>
            </td>
            <td style={{ width: "50%" }}></td>
          </tr>
        </table>
      </div>
    </div>
  );
  //prettier-ignore-end
};

const PharmacyText: React.VFC<any> = () => {
  const mainhost = window.location.host != "app.vetxtend.co.nz" ? true : false;
  //prettier-ignore
  return (
    <div className="pharmacy-text">
      <h4>How to send us a pharmacy order</h4>
      <p>
        One of the biggest advantages for you and your clients is our online pharmacy.
        By sending us their prescription ahead of time, the client doesn't need to post
        us their original and you can start earning commission straight away.</p>
      <p>
        Simply send us a patients prescription via email to {""}
        {mainhost ? (
          <>
            <a
              style={{ fontWeight: "bold" }}
              href="mailto: vetpharmacy@yourpetpa.com.au">
              vetpharmacy@yourpetpa.com.au
            </a> {""}
          </>
        ) : (
          <>
            <a
              style={{ fontWeight: "bold" }}
              href="mailto: vetpharmacy@yourpetpa.co.nz">
              vetpharmacy@yourpetpa.co.nz
            </a> {""}
          </>
        )}
        and include in the body of the email:</p>
      <p>
        Client Name <br />
        Patient (Pet) Name <br />
        Mobile Number <br />
        E-mail Address (mandatory) <br />
        Delivery Address <br />
      </p>
      <p>By emailing the script to our online pharmacy, we can raise an order and invoice your client directly.</p>
      <p>At the end of each month, you will need to post us the hard copies of the prescriptions. You can send to:</p>
      {mainhost ? (
        <p>
          PetPA Pharmacy <br />
          Reply Paid 91355 <br />
          Tweed Heads NSW 2485
        </p>
      ) : (
        <p>
          PetPA Pharmacy <br />
          Unit 4/25 Maru Street <br />
          Mount Maunganui 3116, <br />
          New Zealand
        </p>
      )}
        
    </div>
  );
  //prettier-ignore end
};

const Inclusions: React.VFC<any> = () => {
  const [showServiceAggreement, setShowServiceAggreement] = useState(false);
  return (
    <div className="onboarding inclusions">
      <div className="content-box">
        <h2 className="title-1">
          <div className="flex align-center">
            <img src={vetXtendLogo} />
            <span style={{ fontSize: "35px" }}>Inclusions & Pricing</span>
          </div>
        </h2>
        <hr />
        <div className="text-center" style={{ maxWidth: "100%" }}>
          <h4 style={{ color: "#78b7e4" }}>PARTNERSHIP LEVELS - AT A GLANCE</h4>
          <img
            src="https://vetxtend.com.au/wp-content/uploads/2024/12/Inclusions2.jpg"
            style={{ maxWidth: "100%" }}
          />
        </div>
      </div>
      <Modal
        isOpen={showServiceAggreement}
        closeModal={() => setShowServiceAggreement(false)}
      >
        <ServiceAggreement />
      </Modal>
    </div>
  );
};

const OnboardingHeader: React.VFC<HeaderProps> = ({
  step,
  stepTotal,
  disableProgress,
}) => {
  const [showInclusions, setShowInclusions] = useState(false);
  const steps = Array.from(Array(Number(stepTotal + 1)).keys());
  return (
    <>
      <div className="steps align-center">
        <h2
          style={{
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            columnGap: "10px",
          }}
          onClick={() => history.push("/")}
        >
          Join
          <img src={vetXtendLogoLight} />
        </h2>
        <h5>
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => setShowInclusions(true)}
          >
            Inclusions & Pricing
          </a>
          <Modal
            isOpen={showInclusions}
            closeModal={() => setShowInclusions(false)}
          >
            <Inclusions />
          </Modal>
        </h5>
        {!disableProgress && (
          <h3>
            Step {step} of {stepTotal}
          </h3>
        )}
      </div>
      {!disableProgress && (
        <div className="progress-bar">
          {steps &&
            steps.map((v) => {
              const className = v + 1 == step ? "progress active" : "progress";
              return <span className={className}></span>;
            })}
        </div>
      )}
    </>
  );
};

const OnboardingMenus: React.VFC<{ active: string }> = ({ active }) => {
  const menus = [
    { k: "practice-information", l: "Practice Information", class: "" },
    {
      k: "bank-information",
      l: "Bank Details & Practice Systems",
      class: "",
    },
    { k: "payment-completion", l: "Plan Selection & Complete", class: "" },
  ];
  menus.map((v) => {
    v.class = v.k == active ? "active" : "";
    return v;
  });
  return (
    <ul className="steps-menus">
      {menus &&
        menus.map((m) => {
          return (
            <>
              <li className={m.class}>
                <a
                  onClick={() => {
                    history.push(`/onboarding/${m.k}`);
                  }}
                >
                  {m.l}
                </a>
              </li>
            </>
          );
        })}
    </ul>
  );
};

const ModalAddingPractice: React.VFC<any> = () => {
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const common = useSelector((state: ApplicationState) => state.common);
  return (
    <Modal
      isOpen={common?.actionType == "add-new-practice" ? true : false}
      closeModal={() => {}}
      maxWidth={400}
      maxHeight={320}
      disablePadding
    >
      <div style={{ padding: "20px" }}>
        <p
          style={{
            fontSize: "24px",
            textAlign: "center",
            color: "#51429a",
          }}
        >
          You are still in a process of adding new Practice!
          <br />
          <br />
          <a
            className="btn btn-green"
            onClick={() => changeActionType(undefined)}
          >
            I want to leave.
          </a>
          <a
            className="btn btn-purple"
            onClick={() => history.push("/onboarding/practice-information")}
          >
            Lets continue.
          </a>
        </p>
      </div>
    </Modal>
  );
};

export {
  OnboardingHeader,
  OnboardingMenus,
  Inclusions,
  ServiceAggreement,
  ServiceAggreementNZ,
  TermsAndConditions,
  PharmacyText,
  truckIcon,
  thrivingPetsLogo,
  thrivingPetsLogoLight,
  chatIcon,
  arrowLeftIcon,
  arrowRightIcon,
  hospitalIcon,
  injectionIcon,
  stethoscopeIcon,
  toothbrushIcon,
  desexedIcon,
  storeIcon,
  calculatorIcon,
  faqIcon,
  dogAndCat,
  trashIcon,
  editIcon,
  checkIcon,
  homeIcon,
  shopIcon,
  YPPA_URL,
  VETXTEND_URL,
  vetXtendLogo,
  vetXtendLogoLight,
  inclusionImage,
  ModalAddingPractice,
};
