import { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import "./Onboarding.scss";
import { SEO } from "../../components/app";
import { vetXtendLogoLight } from "./Module";
import iconDownload from "../../assets/vetxtend/icons/icon-download-white.png";
const PracticeQRCode: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [reference, setReference] = useState("");
  const [qrcode, setQrcode] = useState("");
  useEffect(() => {
    const ref = urlParams.get("ref");
    console.log({ h: window.location.host });
    const code =
      window.location.host == "app.vetxtend.co.nz"
        ? `https://yourpetpa.co.nz/?ref=${ref}`
        : `https://yourpetpa.com.au/?ref=${ref}`;
    if (ref) {
      setQrcode(code);
      setReference(ref);
    }
  });
  return (
    <div className="onboarding">
      <SEO title="Practice Qr Code" />
      <div className="banner"></div>
      <div className="content">
        <div className="steps align-center">
          <a href="/">
            <img src={vetXtendLogoLight} />
          </a>
        </div>
        <div className="content-box text-center">
          <div className="download-qrcode">
            <div className="wrapper">
              <div id="qr-code" style={{ textAlign: "right" }}>
                <QRCode value={qrcode} viewBox={`0 0 256 256`} />
              </div>
              <div className="download-bottons">
                <span
                  className="btn-wrapper"
                  onClick={() => {
                    const QRimg = document.getElementById("qr-code");
                    console.log({ img: QRimg?.innerHTML });
                    if (!QRimg) return;
                    const b64dc = btoa(
                      unescape(encodeURIComponent(QRimg.innerHTML))
                    );
                    const a = document.createElement("a");
                    const e = new MouseEvent("click");
                    a.download = `qrcode-${reference}.svg`;
                    a.href = "data:image/svg+xml;base64," + b64dc;
                    a.dispatchEvent(e);
                  }}
                >
                  <span className="img-wrap">
                    <img src={iconDownload} />
                  </span>
                  Download QR Code
                </span>
                <span
                  className="btn-wrapper link"
                  onClick={() => {
                    if (qrcode.length > 0)
                      window.open(String(qrcode), "_blank");
                  }}
                >
                  {qrcode}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PracticeQRCode;
